import _ from 'lodash';

export const AB_TEST_DOCS = {
    AI_TUTOR_ACCESS: {
        name: 'aiTutorAccess',
        init: true,
        inCode: false,
        desc: 'Target group for AI assistant.',
    },
    DEMO_VIDEO: {
        name: 'demoVideo',
        init: false,
        inCode: true,
        desc: 'Target teachers for showing demo video',
    },
    REAL_DEMO: {
        name: 'realDemo',
        init: false,
        inCode: true,
        desc: 'Targets teachers for real demo. Used in TBL lobby',
    },
    TEACHER_DASHBOARD_10: {
        name: 'teacherDashboard10',
        init: false,
        inCode: true,
        desc: 'Used in game creation. TD10-TD12 considered `new`.',
    },
    TEACHER_DASHBOARD_11: {
        name: 'teacherDashboard11',
        init: false,
        inCode: true,
        desc: 'Used in game creation. TD10-TD12 considered `new`.',
    },
    TEACHER_DASHBOARD_12: {
        name: 'teacherDashboard12',
        init: false,
        inCode: true,
        desc: 'Used in game creation. TD10-TD12 considered `new`.',
    },
    NO_ENERGY: {
        name: 'noEnergy',
        init: true,
        inCode: true,
        desc: 'Experiment with Group 5 students, if not participant of Masters League, remove energy.',
    },
};

export const AB_TESTS = _.mapValues(AB_TEST_DOCS, 'name');
